import {Component, ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import {NgAnimateScrollService} from 'ng-animate-scroll';
import {TranslateService} from '@ngx-translate/core';
import {TranslateConfig} from '../@plugins/config/translate.config';
import {MatDrawer} from '@angular/material';


@Component({
  selector: 'app-root',
  templateUrl: './app.template.html'
})
export class AppComponent {

  @ViewChild('sticky', {static: true}) public sticky: ElementRef;
  @ViewChild(MatDrawer, {static: true}) public matDrawer: MatDrawer;

  public activeLanguage: string = 'pt';
  public alpha: number = 0;

  public screenWidth: number;

  constructor(@Inject(TranslateService) public translateService: TranslateService,
              @Inject(TranslateConfig) public translateConfig: TranslateConfig,
              public animateScrollService: NgAnimateScrollService,
              public renderer: Renderer2) {

    // Implementa internacionalização
    this._configTranslate();
  }

  @HostListener('window:resize')
  public screenSize(): number {
    return window.innerWidth;
  }

  public openMenu(): void {
    this.matDrawer.toggle();
  }

  @HostListener('window:scroll', ['$event'])
  public doSomething(event) {
    this.alpha = (Math.round((window.pageYOffset / 500) * 100) / 100);
    this.renderer.setStyle(this.sticky.nativeElement, 'background', `rgba(161, 21, 37, ${this.alpha})`);
  }

  public go(id: string) {
    this.animateScrollService.scrollToElement(id, 1000);
    // Verifica se o tamanho da tela é menor que 1024
    if (this.screenSize() <= 1023) {
      this.matDrawer.toggle();
    }
  }
  public goUrl() {
    window.location.href = 'https://dunamistg.fundportal.io/auth/login/?next=/app/funds/221978';
  }

  /**
   * Troca o idioma da aplicação a partir de um menu de seleção
   * @param language
   */
  public changeLanguage(language: string) {
    this.translateService.use(this.translateConfig.use(language));
    this.activeLanguage = language;
  }

  public onRedirect(url: string) {
    window.open(url, '_blank');
  }

  public onCloseMenu() {
    this.matDrawer.toggle();
  }

  /**
   * Configura componentes de internacionalização
   */
  private _configTranslate(): void {
    const REGEX: any = /en|pt/;
    this.translateService.addLangs(['en', 'pt']);
    this.translateService.setDefaultLang(this.translateConfig.defaultLanguage);
    this.translateService.use(this.translateConfig.activeLang(REGEX));
    this.translateConfig.changeLang.subscribe(() => {
      this.translateService.use(this.translateConfig.activeLang(REGEX));
    });
  }
}
